<script>
	let showPromo = true
	import { browser } from '$app/environment';
	import { Delete } from 'lucide-svelte';
	import { createEventDispatcher } from 'svelte';

	export let showHint1 = true;
	export let showHint2 = false;
	export let todayHint1;
	export let todayHint2;
	let visit_count;
	let link_indicator = 0;

	const dispatch = createEventDispatcher();

	const find_Link = (visit_count) => {
            if (visit_count <= 6) {
                if (visit_count%4 <= 2 && visit_count%4 >= 1) {
                    link_indicator = 0;
                } else {
                    link_indicator = 1;
                }
            } else {
                link_indicator = Math.floor(Math.random()*2)
            }


			return link_indicator
        }


	function checkAnswer() {
		dispatch('check', {});
	}

	import { guess, guessLength, todaysSolutionLength, attempts } from '../store.js';

	// detect keypresses
	if (browser) {
		visit_count = +localStorage.getItem('site-visit');
		link_indicator = find_Link(visit_count);
		document.addEventListener('keypress', function (event) {
			if (event.code === 'Enter') {
				if ($guessLength === $todaysSolutionLength && $attempts < 3) {
					checkAnswer();
				}
			}
		});
	}

	function keypress(letter) {
		if (letter === 'enter') {
		} else if (letter === 'del') {
			$guess = $guess.substring(0, $guess.length - 1);
		} else {
			$guess = $guess + letter;
		}
	}
</script>

<div class="keyboard-wrap">
	<div class="promo"><a href="/subscribe">🔔 Sign up for a daily reminder</a></div>
	<div class="hints">
		Need help?
		{#if showHint1}
			<div class="hint1">
				💡
				{#if showHint1}
					<div class="hint">{todayHint1}</div>
				{/if}
			</div>
		{/if}

		{#if showHint2}
			<div class="hint2">
				💡💡
				{#if showHint1}
					<div class="hint">{todayHint2}</div>
				{/if}
			</div>
		{/if}

		<div class="mobile-guide">
			<a href="https://mediahack.notion.site/Cluedle-Cryptic-Clue-Guide-80d16fc0e4ed45958442af354e0c288b"><strong>cryptic clue guide</strong></a> &nbsp;|&nbsp; <a href="/chat"><strong>💬 Chat</strong></a>
		</div>
		<div class="desktop-guide">
			<a href="https://mediahack.notion.site/Cluedle-Cryptic-Clue-Guide-80d16fc0e4ed45958442af354e0c288b"><strong>cryptic clue guide</strong></a> &nbsp;|&nbsp; <a href="/chat"><strong>💬 Chat</strong></a>
		</div>

	</div>
	<!-- <div class="keyboard-tools">Read the <a href="/learn"><strong>cryptic clue guide</strong></a></div> -->
	<div class="keyboard">
		<div class="keyboard-row keyboard-row-one">
			<div class="key" on:click={() => keypress('q')}>Q</div>
			<div class="key" on:click={() => keypress('w')}>W</div>
			<div class="key" on:click={() => keypress('e')}>E</div>
			<div class="key" on:click={() => keypress('r')}>R</div>
			<div class="key" on:click={() => keypress('t')}>T</div>
			<div class="key" on:click={() => keypress('y')}>Y</div>
			<div class="key" on:click={() => keypress('u')}>U</div>
			<div class="key" on:click={() => keypress('i')}>I</div>
			<div class="key" on:click={() => keypress('o')}>O</div>
			<div class="key" on:click={() => keypress('p')}>P</div>
		</div>

		<div class="keyboard-row keyboard-row-two">
			<div class="key" on:click={() => keypress('a')}>A</div>
			<div class="key" on:click={() => keypress('s')}>S</div>
			<div class="key" on:click={() => keypress('d')}>D</div>
			<div class="key" on:click={() => keypress('f')}>F</div>
			<div class="key" on:click={() => keypress('g')}>G</div>
			<div class="key" on:click={() => keypress('h')}>H</div>
			<div class="key" on:click={() => keypress('j')}>J</div>
			<div class="key" on:click={() => keypress('k')}>K</div>
			<div class="key" on:click={() => keypress('l')}>L</div>
		</div>

		<div class="keyboard-row keyboard-row-three">
			<div
				class="key key-enter {$guessLength === $todaysSolutionLength && $attempts < 3
					? 'enabled'
					: 'disabled'}"
				on:click={() => checkAnswer()}
			>
				ENTER
			</div>
			<div class="key" on:click={() => keypress('z')}>Z</div>
			<div class="key" on:click={() => keypress('x')}>X</div>
			<div class="key" on:click={() => keypress('c')}>C</div>
			<div class="key" on:click={() => keypress('v')}>V</div>
			<div class="key" on:click={() => keypress('b')}>B</div>
			<div class="key" on:click={() => keypress('n')}>N</div>
			<div class="key" on:click={() => keypress('m')}>M</div>
			<!-- <div class="key key-del" on:click={() => keypress('del')}>
          <div class="del-wrap"><Delete size="25"/></div>
        </div> -->
			<div class="key key-del" on:click={() => keypress('del')} on:keydown={() => keypress('del')}>
				<div class="del-wrap">DEL</div>
			</div>
		</div>
	</div>
	{#if showPromo}
		<!-- {#if link_indicator == 0} -->
			<!-- <div class="newsletter-promo">
				
				<span class="hide-box"><a href="subscribe">Click here to receive a new clue daily, it's free</a></span>
				<span class="hide-promo" on:click={() => { showPromo = false }} on:keydown={() => { showPromo = false }}>&nbsp;Hide&nbsp;</span>
			</div> -->
		<!-- {:else } -->
			<div class="newsletter-promo" style="background: #FF0300;">
					
				<span class="hide-box"><a href="/2023" target="_blank">🎉 The best & worst of 2023: PLAY </a></span>
				<span class="hide-promo" on:click={() => { showPromo = false }} on:keydown={() => { showPromo = false }}>&nbsp;Hide&nbsp;</span>
			</div>	
		<!-- {/if} -->
	{/if}
</div>

<style>
	.keyboard-wrap {
		font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, 'Roboto', Arial, Helvetica, sans-serif;
		/* border: solid 1px red; */
		width: 100%;
		position: fixed;
		bottom: 60px;
		left: 0px;
	}
	.keyboard {
		width: 98%;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		font-size: 0.9rem;
	}
	.keyboard-row {
		display: grid;

		grid-gap: 5px;
		margin-top: 5px;
	}
	.keyboard-row-one {
		grid-template-columns: repeat(10, 1fr);
	}
	.keyboard-row-two {
		grid-template-columns: repeat(9, 1fr);
		padding-left: 30px;
		padding-right: 30px;
	}
	.keyboard-row-three {
		grid-template-columns: repeat(9, 1fr);
	}
	.key {
		position: relative;
		background: lightgray;
		border-radius: 5px;
		padding: 10px;
		cursor: pointer;
		min-height: 5vh;
		padding-top: 5px;
		font-weight: 400;
		font-size: 0.9rem;
		border: solid 1px rgb(164, 164, 164);
		border: solid 2px lightgray;
		border: none;
	}
	.key-del,
	.key-enter {
		min-width: 50px;
	}
	.key:hover {
		background: gray;
		color: #fff;
	}
	.key-del:hover {
		background: indianred;
		color: #fff !important;
	}
	.key-enter:hover {
		/* background: rgb(0, 190, 0); */
		background: lightgray;
		color: #000;
	}
	.enabled {
		background: limegreen !important;
	}
	.disabled {
		pointer-events: none;
	}
	.disabled:hover {
		/* background: inherit; */
	}
	.del-wrap {
		position: absolute;
		width: 70%;
		text-align: center;
	}

	.keyboard-tools {
		color: gray;
		text-transform: uppercase;
		font-size: 0.8rem;
		padding-bottom: 5px;
	}
	.keyboard-tools a {
		color: inherit;
		/* font-weight: 700; */
		text-decoration: underline;
	}
	.hints {
		font-size: 0.8rem;
		text-transform: uppercase;
		/* font-weight: bold; */
	}
	.hints a {
		color: #be3131;
	}

	.hints * {
		display: inline-block;
	}
	/* :global(.hint-box) {
      position: relative;
      color: #000;
      width: auto;
      width: 100px;
      margin-left: auto;
      margin-right: auto;
      padding: 5px;
      cursor: pointer;
      font-size: 0.9rem;
      text-transform: uppercase;
      z-index: 3000;
    } */

	.hint-hover {
		position: absolute;
		z-index: 1000 !important;
		top: 45px;
		left: -50%;
		background: #be3131;
		padding: 5px 15px;
		border: solid 1px lightgray;
		display: none;
		color: #fff;
		min-width: 250px;
		font-size: 0.9rem;
		line-height: 1.5rem;
		text-transform: capitalize;
		text-align: center;
	}
	:global(.hint-box:hover .hint-hover) {
		display: block;
	}

	.hint1,
	.hint2 {
		position: relative;
		padding: 0px 10px;

		border: solid 1px rgb(207, 207, 207);
		/* border: none; */
		background: rgb(255, 255, 255);
		margin-bottom: 3px;
		border-radius: 50px;
		text-align: center;
		color: #000;
		margin-right: 5px;
		margin-left: 5px;
	}
	.hint1:hover,
	.hint2:hover {
		background: lightgray;
		cursor: pointer;
	}

	.hint {
		position: absolute;
		bottom: 40px;
		left: -10vh;
		display: none;
		z-index: 300;
		width: 250px;
		/* min-width: 100px; */
		background: #f5f5f5;
		border: solid 1px #eee;
		line-height: 1.4rem;
		padding: 15px;
		-webkit-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.1);
		box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.1);
		text-align: left;
		padding-bottom: 10px;
	}

	.hint1:hover > .hint {
		display: block;
	}
	.hint2:hover > .hint {
		display: block;
	}

	.mobile-guide {
		display: none;
	}
	.desktop-guide {
		display: inline-block;
	}
	.newsletter-promo {
		margin-top: 5px;
		font-size: 1rem;
		line-height: 1rem;
    background: red;
    /* max-width: 600px; */
    margin-left: auto;
    margin-right: auto;
    position: fixed; 
    bottom: 0px;
    width: 100%;
	/* padding-top: 5px; 
	padding-bottom: 5px; */
	display: grid; 
	grid-template-columns: auto 40px;
	}
	.newsletter-promo a {
		color: #fff;
	}
	.hide-promo { 
		color: #fff; text-transform: uppercase; 
		font-size: 0.8rem; 
		cursor: pointer;
		background: #000;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.hide-box { 
		padding: 5px;
	}

	@media only screen and (max-width: 800px) {
		.keyboard-wrap {
			bottom: 40px;
		}
		.keyboard-row {
			grid-gap: 3px;
		}
		.key-del {
			min-width: 30px;
		}
		.hint {
			bottom: 30px;
			width: 150px;
			line-height: 1.1rem;
			font-size: 0.7rem;
			padding: 10px;
		}
		.desktop-guide {
			display: none;
		}
		.mobile-guide {
			display: block;
		}
		.newsletter-promo { 
			font-size: 0.8rem;
			line-height: 1rem;
		
		}
		.hide-promo {
			font-size: 0.7rem;
			
		
		}
		.key { 
			padding: 5px;
		}
	}
	.promo { 
      /* margin-top: 20px; */
      font-size: 0.8rem;
	  text-transform: uppercase;
	  font-weight: 700;
	  color: #BE3131;
    }
	.promo a { 
color: #BE3131;
	}
	
</style> 
